export const appCdnURL = ""

export const appBuildAssetsDir = "/_nuxt/"

export const appHead = {"meta":[{"charset":"utf-8"},{"name":"msapplication-TileColor","content":"#000000"},{"name":"theme-color","content":"#ffffff"},{"name":"viewport","content":"width=device-width, initial-scale=1, maximum-scale=1"},{"name":"google-site-verification","content":"v_qbk16plced5KlWBqaz1SGgIPHPclB62NRTlAFV1yM"},{"name":"yandex-verification","content":"ac203f0e1dc15923"}],"link":[],"style":[],"script":[{"type":"text/javascript","src":"https://cdn.skillbox.pro/frontend-libs/cookie-sync/1.14.0/index.min.js","async":true},{"type":"text/javascript","src":"https://widget.cloudpayments.ru/bundles/cloudpayments/","async":true}],"noscript":[]}

export const appBaseURL = "/"

export const appLayoutTransition = false

export const appPageTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const nuxtLinkDefaults = {"componentName":"NuxtLink"}

export const asyncDataDefaults = {"deep":true}

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'